body .p-component {
   font-family: inherit; 
}



.Card {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-top: 1em;
    font-size: 14px;
    margin-bottom: 4px;
    background-color: white;
}

.CardRow {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 2px;
    padding-bottom: 2px;
    padding-left: 0.5rem;
    border-bottom: 1px solid black;
    margin-left: 1rem;
    margin-right: 1rem;
}

.CardTitle {
    font-size: 18px;
    background-color: grey;
}

.CardRow div:nth-child(2) input, .CardRow div:nth-child(2) select  {
    width: 56px;
    display: inline-block;
    margin: 0;
}

.CardRow div:nth-child(1)  {
    flex-grow: 1;
    width: 4em;
}

.CardRow .p-inputtext {
    padding-top: 0px;
    padding-bottom: 0px;
}

.CardRow .p-dropdown {
    min-height: 1.6em;
}

.Total {
    margin-top: 2px;
    background-color: silver;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-button.noButton {
    margin-right: 1em;
}

.confirm-dialog {
    max-width: 218px;

}